import React, { useState, createContext } from 'react';
import { IJob, ITeamUser, JobSummary } from 'src/api/models/Job';
import { IMeetingType } from 'src/api/models/Meeting';
import { loadMeetingTypes } from 'src/api/meetings';

import { AppToast } from '../components/Toasts/AppToast';
import { loadJobs, loadJobTeam, loadJobById, loadJobSummary } from '../api/jobs';
import { IUser } from '../api/models/User';
import { loadJobUsers } from '../api/user';

interface IJobsContext {
    jobs: IJob[];
    team: ITeamUser[];
    users: IUser[];
    jobSummary: JobSummary;
    meetingTypes: IMeetingType[];
    selectedJob: IJob | null;
    setSelectedJob: (job: IJob | null) => void;
    setMeetingTypes: (meetingTypes: IMeetingType[]) => void;
    loading: boolean;
    loadSummary: () => Promise<void>;
    rehydrateJob: () => Promise<void>;
    fetchJobs: () => Promise<void>;
    fetchTeam: () => Promise<void>;
    refreshTeam: () => Promise<void>;
    refreshMeetingTypes: () => Promise<void>;
    loadingTeam: boolean;
}
export const JobsContext = createContext<IJobsContext>({
    jobs: [],
    team: [],
    users: [],
    meetingTypes: [],
    jobSummary: {
        ctcJob: null,
        constraints: [],
        due: [],
        checklists: [],
    },
    selectedJob: null,
    setSelectedJob: () => null,
    setMeetingTypes: () => null,
    loading: true,
    loadingTeam: false,
    loadSummary: () => Promise.resolve(),
    fetchJobs: () => Promise.resolve(),
    fetchTeam: () => Promise.resolve(),
    refreshTeam: () => Promise.resolve(),
    refreshMeetingTypes: () => Promise.resolve(),
    rehydrateJob: () => Promise.resolve(),
});

export const JobsProvider: React.FC = ({ children }) => {
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [selectedJob, setSelectedJob] = useState<IJob | null>(null);
    const [loading, setLoading] = useState(true);
    const [loadingTeam, setLoadingTeam] = useState(false);
    const [team, setTeam] = useState<ITeamUser[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const [jobSummary, setJobSummary] = useState<JobSummary>({ constraints: [], due: [], checklists: [], ctcJob: null });
    const [meetingTypes, setMeetingTypes] = useState<IMeetingType[]>([]);
    React.useEffect(() => {
        fetchJobs();
        //fetchTeam();
    }, []);

    React.useEffect(() => {
        fetchTeam();
        getMeetingTypes();
        loadSummary();
    }, [selectedJob]);

    const rehydrateJob = async () => {
        if (!selectedJob) return;
        try {
            const data = await loadJobById(selectedJob.job_number);
            setSelectedJob(data);
        } catch (err) {
            AppToast.show({ message: `Error Loading Job Data: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
    };

    const fetchJobs = async () => {
        setLoading(true);
        try {
            const data = await loadJobs();
            setJobs(data);
        } catch (err) {
            AppToast.show({ message: `Error Loading Job Data: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
        setLoading(false);
    };

    const loadSummary = async () => {
        if (!selectedJob) return;
        try {
            const data = await loadJobSummary(selectedJob.id);
            setJobSummary(data);
        } catch (err) {
            AppToast.show({ message: `Error Loading Job Summary: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
    };

    const loadTeam = async () => {
        if (!selectedJob) return;
        try {
            const data = await loadJobTeam(selectedJob.id);
            setTeam(data);
        } catch (err) {
            AppToast.show({ message: `Error Loading Team Data: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
    };

    const getMeetingTypes = async () => {
        if (!selectedJob) return;
        try {
            const data = await loadMeetingTypes(selectedJob.id);
            setMeetingTypes(data);
        } catch (err) {
            AppToast.show({ message: `Error Loading Meeting Type Data: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
    };

    const loadUserOptions = async () => {
        if (!selectedJob) return;
        try {
            const d = await loadJobUsers(selectedJob.id);
            setUsers(d);
        } catch (err) {
            console.log('error: ', err.toLocaleString());
        }
    };

    const fetchTeam = async () => {
        setLoadingTeam(true);
        await loadTeam();
        await loadUserOptions();
        setLoadingTeam(false);
    };

    const refreshMeetingTypes = async () => {
        await getMeetingTypes();
    };

    const refreshTeam = async () => {
        await loadTeam();
        await loadUserOptions();
    };

    return (
        <JobsContext.Provider
            value={{
                jobs,
                team,
                users,
                jobSummary,
                loading,
                loadingTeam,
                setSelectedJob,
                setMeetingTypes,
                meetingTypes,
                refreshMeetingTypes,
                selectedJob,
                fetchJobs,
                fetchTeam,
                refreshTeam,
                rehydrateJob,
                loadSummary,
            }}
        >
            {children}
        </JobsContext.Provider>
    );
};

export const useJobs: () => IJobsContext = () => {
    const context = React.useContext(JobsContext);
    return { ...context };
};
