import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { CostCodeAdjustmentLineItem } from 'src/api/models/CTC';

interface CostCodeLineProps {
    label: string;
    jobId: string;
    jdtCost: number;
    revisedEstimate: number;
    costCode: string;
    ctcHours: number;
    jtdRate: number;
    onAdjustmentChange: (item: CostCodeAdjustmentLineItem) => void;
}
const YELLOW = '#f6f233';
const CODE_LOOKUP: any = {
    '10': 'Labor',
    '20': 'Materials',
    '30': 'Equip Rental',
    '70': 'Permits Rental',
    '40': 'Subcontractors',
    '50': 'Other',
};
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

export const CostCodeLine: React.FC<CostCodeLineProps> = ({ label, onAdjustmentChange, jdtCost, revisedEstimate, costCode, ctcHours, jobId, jtdRate }) => {
    const [value, setValue] = React.useState<CostCodeAdjustmentLineItem>({
        ws_job_number: jobId,
        cost_code_id: costCode,
        ctc_hours: ctcHours,
        jtd_rate: jtdRate,
        previous_ctc_hours: ctcHours,
        previous_jtd_rate: jtdRate,
        description: label,
    });
    const handleFocus = (event: any) => {
        event.target.select();
    };
    const onCTCChange = (val: string) => {
        const newVal = {
            ...value,
            jtd_rate: 1,
            ctc_hours: Number(val),
        };
        setValue(newVal);
        onAdjustmentChange(newVal);
    };
    const onValueChange = async (field: 'ctc_hours' | 'jtd_rate', val: string) => {
        const newVal = {
            ...value,
            [field]: val,
        };
        setValue(newVal);
        onAdjustmentChange(newVal);
    };
    const ctc = value.ctc_hours * value.jtd_rate;
    const costAtCompletion = ctc + jdtCost;
    const overUnder = costAtCompletion - revisedEstimate;
    const settings = CODE_LOOKUP[costCode.slice(0, 2)];
    const isLabor = settings === 'Labor';
    return (
        <TableBody>
            <TableRow tabIndex={-1}>
                <TableCell align="left" colSpan={1}>
                    {label}
                </TableCell>
                <TableCell align="center" colSpan={1} style={{ backgroundColor: YELLOW }}>
                    {isLabor && <input style={{ maxWidth: 75 }} value={value.ctc_hours} type="number" onFocus={handleFocus} onChange={(e) => onValueChange('ctc_hours', e.target.value)} />}
                </TableCell>
                <TableCell align="center" colSpan={1} style={{ backgroundColor: YELLOW }}>
                    {isLabor && <input style={{ maxWidth: 75 }} value={value.jtd_rate} type="number" onFocus={handleFocus} onChange={(e) => onValueChange('jtd_rate', e.target.value)} />}
                </TableCell>
                <TableCell align="right" colSpan={1} style={{ backgroundColor: YELLOW }}>
                    {isLabor ? <>{formatter.format(ctc)}</> : <input style={{ maxWidth: 75 }} value={ctc} type="number" onFocus={handleFocus} onChange={(e) => onCTCChange(e.target.value)} />}
                </TableCell>
                <TableCell align="right" colSpan={1} style={{ backgroundColor: YELLOW }}>
                    {costAtCompletion < 0 ? <span className="text-danger">({formatter.format(costAtCompletion * -1)})</span> : formatter.format(costAtCompletion)}
                </TableCell>
                <TableCell align="right" colSpan={1}>
                    {overUnder < 0 ? <span className="text-danger">({formatter.format(overUnder * -1)})</span> : formatter.format(overUnder)}
                </TableCell>
            </TableRow>
        </TableBody>
    );
};
