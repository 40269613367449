import React from 'react';
import { Header } from 'src/components/Header';
import { Row, Col } from 'react-grid-system';
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import { PhaseDialog } from 'src/components/Dialogs/PhaseDialog';
import { IPhase, IChecklistItem } from 'src/api/models/Checklist';
import { listItems, removePhase } from 'src/api/checklists';
import { BottomToast } from 'src/components/Toasts';
import { ChecklistItemDialog } from 'src/components/Dialogs/ChecklistItemDialog';
import { ChecklistItemTable } from 'src/components/Tables/ChecklistItemTable';
import { ConfirmPopover } from 'src/components/ConfirmPopover';
import { useParams, useHistory } from 'react-router-dom';
import { getMilestoneTemplateById, updateMilestoneTemplate, deleteMilestoneTemplate } from '../../../../api/milestones';
import { AppToast } from '../../../../components/Toasts/AppToast';

export const CreateMilestoneTemplate: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [phases, setPhases] = React.useState<IPhase[]>([]);
    const [items, setItems] = React.useState<IChecklistItem[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IChecklistItem | null>(null);
    const [selectedPhaseId, setSelectedPhaseId] = React.useState<string | null>(null);
    const [name, setName] = React.useState('');
    const [tname, setTname] = React.useState('');
    // React.useEffect(() => {
    //     loadPhases();
    // }, [selectedPhaseId]);

    React.useEffect(() => {
        loadTemplate();
    }, [params.id]);

    const loadTemplate = async (load = true) => {
        setLoading(load);
        setError(null);

        try {
            const data = await getMilestoneTemplateById(params.id);
            const items = await listItems('default', { default: true, phase: selectedPhaseId, milestone_template_id: params.id });
            setItems(items);
            setName(data.template.name);
            setTname(data.template.name);
            setPhases(data.phases);
        } catch (err) {
            setError(err.toString());
        }
        setLoading(false);
    };

    // const loadPhases = async (load = true) => {
    //     setError(null);
    //     setLoading(load);
    //     try {
    //         const data = await listPhases();
    //         setPhases(data);
    //         const items = await listItems('default', { default: true, phase: selectedPhaseId });
    //         setItems(items);
    //     } catch (err) {
    //         setError(err.toLocaleString());
    //     }
    //     setLoading(false);
    // };

    const remove = async (id: string) => {
        await removePhase(id);
        BottomToast.show({ message: 'Phase removed!', intent: 'success', icon: 'tick-circle' });
        await loadTemplate(false);
    };

    const renderPhases = () => {
        return phases.map((phase) => {
            const isSelected = selectedPhaseId === phase.id;
            return (
                <Col xs={12} md={3} key={phase.id} className="m-b-15">
                    <Card
                        interactive
                        style={{ backgroundColor: isSelected ? '#e9e9e9' : '#fff' }}
                        onClick={() => (isSelected ? setSelectedPhaseId(null) : setSelectedPhaseId(phase.id))}
                        elevation={isSelected ? 3 : 0}
                    >
                        <h4 className="m-0 m-b-10">{phase.title}</h4>
                        <ConfirmPopover
                            contentTitle="Are you sure?"
                            confirmButtonIntent="danger"
                            contentBody={
                                <p>
                                    Are you sure you want to remove this phase? All items associated with <b>{phase.title}</b> will also be removed.
                                </p>
                            }
                            confirmButtonText="Delete Phase"
                            onConfirmClick={() => remove(phase.id)}
                        >
                            <Button small intent="danger">
                                Delete
                            </Button>
                        </ConfirmPopover>
                    </Card>
                </Col>
            );
        });
    };
    const updateTemplate = async () => {
        await updateMilestoneTemplate(params.id, { name });
        AppToast.show({ message: `"${name}" updated!`, intent: 'success', icon: 'tick-circle' });
    };
    const lockButton = <Button icon="tick" intent="primary" text="Save" onClick={updateTemplate} disabled={tname === name} />;
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            updateTemplate();
        }
    };
    const deleteTemplate = async () => {
        await deleteMilestoneTemplate(params.id);
        AppToast.show({ message: `"${name}" deleted!`, intent: 'success', icon: 'tick-circle' });
        history.push('/settings/milestones');
    };
    return (
        <>
            <Header
                title="Milestones"
                breadcrumbs={['Settings', 'Milestones', 'Create Template']}
                goBackIndices={[1]}
                goBackPath={`/settings/milestones`}
                actionComponent={
                    <>
                        <Row className="m-b-10">
                            <Col>
                                <Button small text="Back" onClick={() => history.push('/settings/milestones')} className="m-t-5 m-l-5" />
                                <ConfirmPopover
                                    contentTitle="Are you sure?"
                                    confirmButtonIntent="danger"
                                    contentBody={<p>Are you sure you want to remove this template? This will not have an effect on any jobs created with this template.</p>}
                                    confirmButtonText="Delete"
                                    onConfirmClick={deleteTemplate}
                                >
                                    <Button small text="Delete Template" className="m-t-5 m-l-5" intent="danger" />
                                </ConfirmPopover>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className="m-r-5" intent="primary" icon="add" text="Add Phase" onClick={() => setDialogOpen(true)} />
                                <Button intent="primary" icon="updated" text="Add Item" onClick={() => setItemDialogOpen(true)} />
                            </Col>
                        </Row>
                    </>
                }
            />
            <PhaseDialog isOpen={dialogOpen} onClose={() => setDialogOpen(false)} onCreateComplete={() => loadTemplate(false)} templateId={params.id} />
            {error && <p>{error}</p>}
            <Card className="m-b-15">
                <Row>
                    <Col>
                        <FormGroup label="Template Name">
                            <InputGroup onKeyDown={handleKeyDown} rightElement={lockButton} placeholder="Enter template name" onChange={(e) => setName(e.target.value)} fill value={name} />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            <Row>{renderPhases()}</Row>
            <ChecklistItemTable data={items} onRowClick={(item) => setSelectedItem(item)} loading={loading} hideStatus />
            {selectedItem && (
                <ChecklistItemDialog
                    //selectedJobId={selectedJob.id}
                    templateId={params.id}
                    data={selectedItem}
                    isOpen={selectedItem !== null}
                    onClose={() => setSelectedItem(null)}
                    onCreateComplete={() => loadTemplate(false)}
                    onUpdateComplete={() => loadTemplate(false)}
                />
            )}
            <ChecklistItemDialog
                //selectedJobId={selectedJob.id}
                templateId={params.id}
                isOpen={itemDialogOpen}
                onClose={() => setItemDialogOpen(false)}
                onCreateComplete={() => loadTemplate(false)}
                onUpdateComplete={() => loadTemplate(false)}
            />
        </>
    );
};
