import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { styled, TypographyProps, Button } from '@mui/material';
import { IEnhancedPhase } from 'src/api/models/Checklist';

const Title = styled(Typography)(() => ({
    lineHeight: 2,
    width: '33%',
}));

const PhaseTitle = styled(Typography)<TypographyProps>(() => ({
    lineHeight: 2,
    width: '33%',
    textAlign: 'center',
}));

interface ChecklistCardProps {
    title: string;
    items: IEnhancedPhase[];
    jobId: number;
}

export const ChecklistCard: React.FC<ChecklistCardProps> = ({ title, items, jobId }) => {
    const history = useHistory();

    const renderPhases = () => {
        return items.map((phase) => {
            const percentage = phase.target_items.length === 0 ? 0 : (phase.completed_items.length / phase.target_items.length) * 100;
            return (
                <Stack key={phase.id} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Title>{phase.title}</Title>
                    <PhaseTitle>{phase.target_items.length - phase.completed_items.length}</PhaseTitle>
                    <PhaseTitle>{percentage}%</PhaseTitle>
                </Stack>
            );
        });
    };
    return (
        <Card sx={{ mb: 3, pb: 2 }}>
            <CardHeader
                action={
                    <Button size="small" sx={{ mt: 1 }} onClick={() => history.push(`/jobs/${jobId}/milestones`)}>
                        View All
                    </Button>
                }
                className="p-b-5"
                titleTypographyProps={{ variant: 'h6' }}
                title={title}
            />
            <CardContent style={{ height: 200, overflow: 'auto', paddingBottom: 10 }}>
                {items.length === 0 ? (
                    <Box sx={{ textAlign: 'center', mt: '50px' }}>
                        <Typography>No Checklist Items</Typography>
                    </Box>
                ) : (
                    <>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <Title sx={{ fontWeight: 'bold' }}>Phase</Title>
                            <Title sx={{ fontWeight: 'bold', textAlign: 'center' }}>Open Items</Title>
                            <Title sx={{ fontWeight: 'bold', textAlign: 'center' }}>% Complete</Title>
                        </Stack>
                        <Box>{renderPhases()}</Box>
                    </>
                )}
            </CardContent>
        </Card>
    );
};
