import { IDefaultFolderPayload, IDefaultFolder, IFolderTemplateResponse, IFolderTemplate } from './models/DefaultFolder';
import { getAuthOptions } from './helpers';
import AppApi from './index';
const api = AppApi.getApi();

export const createFolderTemplate = async (): Promise<IDefaultFolder> => {
    const url = `folderTemplates`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const updateFolderTemplate = async (templateId: string, payload: { name: string }): Promise<IDefaultFolder> => {
    const url = `folderTemplates/${templateId}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const deleteFolderTemplate = async (templateId: string): Promise<IDefaultFolder> => {
    const url = `folderTemplates/${templateId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const getFolderTemplates = async (): Promise<IFolderTemplate[]> => {
    const url = `folderTemplates`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const getFolderTemplateById = async (id: string): Promise<IFolderTemplateResponse> => {
    const url = `folderTemplates/${id}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createFolder = async (templateId: string, folder: IDefaultFolderPayload): Promise<IDefaultFolder> => {
    const url = `folderTemplates/${templateId}/folder`;
    const { data } = await api.post(url, folder, await getAuthOptions());
    return data;
};
export const updateFolder = async (templateId: string, folderId: string, folder: IDefaultFolderPayload): Promise<IDefaultFolder> => {
    const url = `folderTemplates/${templateId}/folder/${folderId}`;
    const { data } = await api.put(url, folder, await getAuthOptions());
    return data;
};

export const removeTemplateFolder = async (templateId: string, folderId: string): Promise<IDefaultFolder> => {
    const url = `folderTemplates/${templateId}/folder/${folderId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
