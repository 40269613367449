import React from 'react';
import Select from 'react-select';
import { IOrganizationClient } from 'src/api/models/Organization';
import { searchOrganizationClients } from '../../../api/organization';
import AsyncSelect from 'react-select/async';

interface IClientSelectorProps {
    onChange: (client: IOrganizationClient | undefined) => void;
    initialValue?: IOrganizationClient | undefined;
    clientOptions?: IOrganizationClient[];
}

export const ClientSelector: React.FC<IClientSelectorProps> = ({ onChange, initialValue, clientOptions = undefined }) => {
    const [selected, setSelected] = React.useState<IOrganizationClient | undefined>(initialValue);

    React.useEffect(() => {
        if (clientOptions) {
            setSelected(clientOptions[0] || undefined);
        }
    }, [clientOptions]);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const onValueChange = (value: IOrganizationClient | undefined) => {
        setSelected(value);
    };

    const promiseOptions = (inputValue: string) => searchOrganizationClients(inputValue);
    return (
        <>
            {clientOptions ? (
                <Select
                    isClearable
                    className="basic-single"
                    placeholder="Select a customer..."
                    classNamePrefix="react-select"
                    getOptionLabel={(o) => `${o.name}`}
                    getOptionValue={(o) => String(o.id)}
                    value={selected}
                    isSearchable
                    options={clientOptions}
                    onChange={(value: any) => {
                        onValueChange(value);
                    }}
                />
            ) : (
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selected}
                    loadOptions={promiseOptions}
                    getOptionLabel={(o) => `${o.name}`}
                    getOptionValue={(o) => String(o.id)}
                    onChange={(value: any) => {
                        onValueChange(value);
                    }}
                />
            )}
        </>

        // <ClientSelect itemPredicate={filterData} itemRenderer={renderItem} items={options} noResults={<MenuItem disabled={true} text="No results." />} onItemSelect={onValueChange}>
        //     <Button text={selected ? selected.name : 'Select customer...'} rightIcon="caret-down" icon="inherited-group" />
        // </ClientSelect>
    );
};
