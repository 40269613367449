import React, { useState, createContext } from 'react';
import { IOrganizationRole, IOrganizationOffice, IOrganizationClient } from 'src/api/models/Organization';
import { IUser } from 'src/api/models/User';
import { CTCJob } from '../api/models/CTC';

interface IDialogsContext {
    createProjectOpen: boolean;
    setCreateProjectOpen: (isOpen: boolean) => void;
    addUserOpen: boolean;
    setAddUserOpen: (isOpen: boolean) => void;
    scopeInfoOpen: boolean;
    setScopeInfoOpen: (isOpen: boolean) => void;
    createClientOpen: boolean;
    setCreateClientDialogOpen: (isOpen: boolean, role?: IOrganizationClient) => void;
    selectedClient: IOrganizationClient | undefined;
    createRoleDialogOpen: boolean;
    setCreateRoleDialogOpen: (isOpen: boolean, role?: IOrganizationRole) => void;
    selectedRole: IOrganizationRole | undefined;
    selectedUser: IUser | undefined;
    createOfficeDialogOpen: boolean;
    setCreateOfficeDialogOpen: (isOpen: boolean, office?: IOrganizationOffice) => void;
    selectedOffice: IOrganizationOffice | undefined;
    setCreateJobDialogOpen: (job: CTCJob | null) => void;
    ctcJob: CTCJob | null;
}

export const DialogContext = createContext<IDialogsContext>({
    createProjectOpen: false,
    setCreateProjectOpen: () => null,
    createClientOpen: false,
    setCreateClientDialogOpen: () => null,
    selectedClient: undefined,
    addUserOpen: false,
    setAddUserOpen: () => null,
    scopeInfoOpen: false,
    setScopeInfoOpen: () => null,
    createRoleDialogOpen: false,
    setCreateRoleDialogOpen: () => null,
    selectedRole: undefined,
    selectedUser: undefined,
    createOfficeDialogOpen: false,
    setCreateOfficeDialogOpen: () => null,
    selectedOffice: undefined,
    setCreateJobDialogOpen: () => null,
    ctcJob: null,
});

export const DialogsProvider: React.FC = ({ children }) => {
    const [createProjectOpen, setCreateProjectOpen] = useState<boolean>(false);
    const [addUserOpen, setAddUser] = useState<boolean>(false);
    const [scopeInfoOpen, setScopeInfoOpen] = useState<boolean>(false);
    const [createRoleDialogOpen, setCreateRoleDialog] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<IOrganizationRole | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
    const [createOfficeDialogOpen, setCreateOfficeOpen] = useState<boolean>(false);
    const [selectedOffice, setSelectedOffice] = useState<IOrganizationOffice | undefined>(undefined);

    const [selectedClient, setSelectedClient] = useState<IOrganizationClient | undefined>(undefined);
    const [createClientOpen, setCreateClientOpen] = useState<boolean>(false);
    const [ctcJob, setCtcJob] = React.useState<CTCJob | null>(null);

    React.useEffect(() => {
        if (!createProjectOpen) {
            setCtcJob(null);
        }
    }, [createProjectOpen]);
    const setCreateJobDialogOpen = (job: CTCJob | null) => {
        setCtcJob(job);
        setCreateProjectOpen(true);
    };
    const setCreateRoleDialogOpen = (isOpen: boolean, role?: IOrganizationRole) => {
        setCreateRoleDialog(isOpen);
        setSelectedRole(role);
    };

    const setAddUserOpen = (isOpen: boolean, user?: IUser) => {
        setAddUser(isOpen);
        setSelectedUser(user);
    };

    const setCreateOfficeDialogOpen = (isOpen: boolean, office?: IOrganizationOffice) => {
        setCreateOfficeOpen(isOpen);
        setSelectedOffice(office);
    };

    const setCreateClientDialogOpen = (isOpen: boolean, client?: IOrganizationClient) => {
        setCreateClientOpen(isOpen);
        setSelectedClient(client);
    };
    return (
        <DialogContext.Provider
            value={{
                createProjectOpen,
                setCreateProjectOpen,
                selectedRole,
                createRoleDialogOpen,
                selectedUser,
                setCreateRoleDialogOpen,
                addUserOpen,
                setAddUserOpen,
                scopeInfoOpen,
                setScopeInfoOpen,
                selectedOffice,
                setCreateOfficeDialogOpen,
                createOfficeDialogOpen,
                createClientOpen,
                setCreateClientDialogOpen,
                selectedClient,
                setCreateJobDialogOpen,
                ctcJob,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};

export const useDialogs: () => IDialogsContext = () => {
    const context = React.useContext(DialogContext);
    return { ...context };
};
