import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Spinner, Button, Tag, NonIdealState } from '@blueprintjs/core';
import { useJobs } from 'src/hooks/useJobs';
import { TeamMemberSelector } from './components/TeamMemberSelector';
import { ITeamUser } from 'src/api/models/Job';
import { removeMemberFromTeam } from 'src/api/jobs';
import { AppToast } from 'src/components/Toasts/AppToast';
import './index.scss';
import { Header2 } from '../../../components/Header2';

export const Team: React.FC = () => {
    const { selectedJob, team, fetchTeam, refreshTeam, loadingTeam } = useJobs();
    React.useEffect(() => {
        fetchTeam();
    }, []);

    const onRemoveClick = async (teamMember: ITeamUser) => {
        if (!selectedJob) return;
        try {
            await removeMemberFromTeam(selectedJob.id, teamMember.user_id);
            await refreshTeam();
        } catch (err) {
            AppToast.show({ message: `Error Removing User: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
    };
    const renderTeam = () => {
        if (team.length === 0) {
            return (
                <div className="no-items-container">
                    <NonIdealState title="No Team Members" icon="people" description="No team members have been added to this job" />
                </div>
            );
        }
        return team.map((t) => {
            return (
                <div key={t.id} className="team-member-row">
                    <Row>
                        <Col>
                            <p>
                                {t.user.first_name} {t.user.last_name}
                            </p>
                        </Col>
                        <Col>
                            <span>
                                {t.user.roles.map((r) => {
                                    return (
                                        <Tag intent="primary" className="m-r-5">
                                            {r.name}
                                        </Tag>
                                    );
                                })}
                            </span>
                        </Col>
                        <Col>
                            <Button className="pull-right m-r-20" minimal small intent="danger" onClick={() => onRemoveClick(t)}>
                                Remove
                            </Button>
                        </Col>
                    </Row>
                </div>
            );
        });
    };
    return (
        <div>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Project Team', link: '' },
                ]}
            />
            <TeamMemberSelector onAddSuccess={refreshTeam} existingUsers={team} />
            <div className="team-member-table">
                {team.length > 0 && (
                    <div className="team-member-row team-member-header">
                        <Row>
                            <Col>
                                <p>Name</p>
                            </Col>
                            <Col>
                                <p>Roles</p>
                            </Col>
                            <Col>
                                <p className="pull-right m-r-20" />
                            </Col>
                        </Row>
                    </div>
                )}
                {loadingTeam ? <Spinner /> : <>{renderTeam()}</>}
            </div>
        </div>
    );
};
