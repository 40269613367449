import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { FormGroup, InputGroup, Radio, RadioGroup } from '@blueprintjs/core';
import { WeatherCard } from '../Weather/WeatherCard';
import { EditDetails } from './EditDetails';
import { ActivityFeedCard } from './ActivityFeedCard';
import { ChecklistCard } from './ChecklistCard';
import { ContactsCard } from './ContactsCard';
import { TeamCard } from './TeamCard';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Header2 } from '../../../components/Header2';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

export const Summary: React.FC = () => {
    const { selectedJob, rehydrateJob, jobSummary, loadSummary } = useJobs();
    const [editing, setEditing] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const history = useHistory();
    React.useEffect(() => {
        try {
            loadSummary();
        } catch (err) {
            console.log('error: ', err);
            setError(err.toLocaleString());
        }
    }, []);
    const getType = (v: string) => {
        const items = v.split('-');
        return items[1] ? items[1] : 'N/A';
    };
    if (!selectedJob) return null;
    return (
        <div>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Summary', link: '' },
                ]}
            />
            {error && <p>{error}</p>}
            <Row>
                <Col sm={12} md={5}>
                    <Row className="m-b-25">
                        <Col>
                            <Card sx={{ pb: 0 }}>
                                <CardHeader
                                    className="p-b-5"
                                    titleTypographyProps={{ variant: 'h6' }}
                                    title="Job Details"
                                    action={
                                        <>
                                            {!editing && (
                                                <Button size="small" onClick={() => setEditing(true)}>
                                                    Edit
                                                </Button>
                                            )}
                                        </>
                                    }
                                />
                                <CardContent>
                                    {editing ? (
                                        <>
                                            <EditDetails job={selectedJob} onCancel={() => setEditing(false)} onSave={rehydrateJob} />
                                        </>
                                    ) : (
                                        <>
                                            <FormGroup label="Job Number - Job Name" labelFor="project-name-input">
                                                <InputGroup disabled id="project-name-input" value={`${selectedJob?.job_number} - ${selectedJob?.name}`} />
                                            </FormGroup>
                                            <FormGroup label="Customer">
                                                <InputGroup disabled id="project-name-input" value={selectedJob?.client?.name} />
                                            </FormGroup>
                                            <FormGroup label="Division" labelFor="job-division-input">
                                                <InputGroup disabled id="job-division-input" value={selectedJob?.division} />
                                            </FormGroup>
                                            <FormGroup label="Job Type">
                                                <InputGroup placeholder="Derived from CTC Job" fill value={getType(selectedJob?.division)} disabled />
                                            </FormGroup>
                                            <FormGroup label="Scope of Work">
                                                <InputGroup disabled fill value={selectedJob?.description} />
                                            </FormGroup>
                                            <Row>
                                                <Col>
                                                    <FormGroup label="Tax Exempt">
                                                        <RadioGroup inline disabled onChange={console.log} selectedValue={selectedJob?.tax_exempt ? 'true' : 'false'}>
                                                            <Radio label="Yes" value="true" />
                                                            <Radio label="No" value="false" />
                                                        </RadioGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup label="Vendor Discounts">
                                                        <RadioGroup disabled inline onChange={console.log} selectedValue={selectedJob?.vendor_discounts ? 'true' : 'false'}>
                                                            <Radio label="Yes" value="true" />
                                                            <Radio label="No" value="false" />
                                                        </RadioGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup label="Bonded">
                                                        <RadioGroup disabled inline onChange={console.log} selectedValue={jobSummary.ctcJob?.bonded === 'yes' ? 'true' : 'false'}>
                                                            <Radio label="Yes" value="true" />
                                                            <Radio label="No" value="false" />
                                                        </RadioGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup label="Jobsite Location">
                                                <InputGroup disabled fill value={selectedJob?.location.address} />
                                            </FormGroup>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    {jobSummary.ctcJob && (
                        <Row>
                            <Col>
                                <Card sx={{ mb: 3, pb: 2 }}>
                                    <CardHeader
                                        action={
                                            <Button size="small" sx={{ mt: 1 }} onClick={() => history.push(`/ctc/view/${selectedJob?.job_number}`)}>
                                                View CTC Job
                                            </Button>
                                        }
                                        className="p-b-5"
                                        titleTypographyProps={{ variant: 'h6' }}
                                        title="CTC Job Details"
                                    />
                                    <CardContent style={{ height: 185, overflow: 'auto', paddingBottom: 10 }}>
                                        <p>
                                            <b>Contract Amount:</b> {formatter.format(jobSummary.ctcJob.contract_amount)}
                                        </p>
                                        <p>
                                            <b>Customer:</b> {jobSummary.ctcJob.customer}
                                        </p>
                                        <p>
                                            <b>Division:</b> {jobSummary.ctcJob.division}
                                        </p>
                                        <p>
                                            <b>Salesperson:</b> {jobSummary.ctcJob.salesperson}
                                        </p>
                                        <p>
                                            <b>Certified Payroll:</b> {jobSummary.ctcJob.certified_payroll}
                                        </p>
                                        <p>
                                            <b>OCIP:</b> {jobSummary.ctcJob.ocip}
                                        </p>
                                        <p>
                                            <b>Liquidated Damages:</b> {jobSummary.ctcJob.lds}
                                        </p>
                                    </CardContent>
                                </Card>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col>
                            <ChecklistCard title="Milestones" items={jobSummary.checklists} jobId={selectedJob.id} />
                        </Col>
                    </Row>
                </Col>
                {selectedJob && (
                    <Col>
                        <Row>
                            <Col>
                                <ActivityFeedCard title="Constraints" items={jobSummary.constraints} />
                            </Col>
                            <Col>
                                <ActivityFeedCard title="Activity By Due Date" items={jobSummary.due} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ContactsCard jobId={selectedJob.id} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TeamCard jobId={selectedJob.id} />
                            </Col>
                        </Row>
                        <Row className="m-b-20">
                            <Col>
                                <WeatherCard job={selectedJob} />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
};
