import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Dialog, Classes, Button, FormGroup, InputGroup, TextArea, Intent, Colors, RadioGroup, Radio } from '@blueprintjs/core';
import { createJob } from 'src/api/jobs';
import { ILocation } from 'src/api/models/Job';
import { useDialogs } from 'src/hooks/useDialogs';
import { useProjects } from 'src/hooks/useProjects';
import { ClientSelector } from './ClientSelector';
import { CTCJobSelector } from 'src/components/Selectors/CTCJobSelector/single';
import { DocumentTemplateSelector } from 'src/components/Selectors/DocumentTemplateSelector';
import { MilestoneTemplateSelector } from 'src/components/Selectors/MilestoneTemplateSelector';
import { PlaceSelector } from 'src/components/Selectors/PlaceSelector';
import { CTCJob } from '../../../api/models/CTC';
import { IFolderTemplate } from '../../../api/models/DefaultFolder';
import { AppToast } from '../../Toasts/AppToast';
import { IMilestoneTemplate } from '../../../api/models/Milestone';

interface ICreateJobDialogProps {
    onCreateComplete: () => void;
}

const defaultLocation = {
    address: '',
    geo: null,
};

export const CreateJobDialog: React.FC<ICreateJobDialogProps> = ({ onCreateComplete }) => {
    const { createProjectOpen, setCreateProjectOpen, ctcJob } = useDialogs();
    const { onNewProjectCreated } = useProjects();
    const [isSaving, setIsSaving] = React.useState(false);
    const [name, setName] = React.useState('');
    const [jobNumber, setJobNumber] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [taxExempt, setTaxExempt] = React.useState(false);
    const [vendorDiscounts, setVendorDiscounts] = React.useState(false);
    const [division, setDivision] = React.useState('');
    //const [client, setClient] = React.useState<IOrganizationClient | undefined>(undefined);
    const [clientId, setClientId] = React.useState<number | null>(null);
    const [location, setLocation] = React.useState<ILocation>(defaultLocation);
    const [error, setError] = React.useState<string | null>(null);
    const [documentTemplateId, setDocumentTemplateId] = React.useState<string | null>(null);
    const [milestoneTemplateId, setMilestoneTemplateId] = React.useState<string | null>(null);
    const onLocationChange = (location: ILocation) => {
        setLocation(location);
    };
    const saveProject = async () => {
        if (!clientId) return setError('Client Required...');
        try {
            setError(null);
            setIsSaving(true);
            const result = await createJob({
                name,
                division,
                description,
                job_number: jobNumber,
                id: -1,
                client_id: clientId,
                organization_id: 1,
                location,
                document_template_id: documentTemplateId,
                milestone_template_id: milestoneTemplateId,
                vendor_discounts: vendorDiscounts,
                tax_exempt: taxExempt,
            });
            setIsSaving(false);
            AppToast.show({ message: `${name} Created!`, intent: 'success', icon: 'tick-circle' });
            closeDialog();
            onNewProjectCreated(result);
        } catch (err) {
            console.log('error: ', err);
            setError(err.message);
            setIsSaving(false);
        }
    };
    const closeDialog = () => {
        setCreateProjectOpen(false);
        setName('');
        setDescription('');
        setDivision('');
        setJobNumber('');
        setClientId(null);
        setError(null);
        setDocumentTemplateId(null);
        setMilestoneTemplateId(null);
        setVendorDiscounts(false);
        setTaxExempt(false);
        setLocation(defaultLocation);
        onCreateComplete();
    };

    React.useEffect(() => {
        onCTCJobChange(ctcJob);
    }, [ctcJob]);

    const onCTCJobChange = (job: CTCJob | null) => {
        if (job) {
            setName(job.ws_job_name);
            setJobNumber(job.ws_job_number);
            setDivision(job.division);
            setDescription(job.ws_job_name);
            setClientId(job.customer);
        } else {
            setName('');
            setJobNumber('');
            setDivision('');
            setClientId(null);
        }
    };

    const onDocumentTemplateChange = (template: IFolderTemplate | null) => {
        if (template) {
            setDocumentTemplateId(template.id);
        } else {
            setDocumentTemplateId(null);
        }
    };
    const onMilestoneTemplateChange = (template: IMilestoneTemplate | null) => {
        if (template) {
            setMilestoneTemplateId(template.id);
        } else {
            setMilestoneTemplateId(null);
        }
    };

    const getType = (v: string) => {
        const items = v.split('-');
        return items[1] ? items[1] : 'N/A';
    };
    return (
        <Dialog usePortal={false} isOpen={createProjectOpen} canOutsideClickClose={false} onClose={closeDialog} title="Create Job" icon="folder-new">
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label="CTC Job" labelInfo="*">
                    <CTCJobSelector ctcJobId={jobNumber} onChange={onCTCJobChange} hideLabel small />
                </FormGroup>
                <FormGroup label="Customer" labelInfo="*">
                    <ClientSelector clientId={clientId} />
                </FormGroup>
                <FormGroup label="Division">
                    <InputGroup placeholder="Derived from CTC Job" fill value={division} disabled />
                </FormGroup>
                <FormGroup label="Job Type">
                    <InputGroup placeholder="Derived from CTC Job" fill value={getType(division)} disabled />
                </FormGroup>
                <FormGroup label="Scope of Work" labelInfo="*">
                    <TextArea placeholder="Enter a scope of work for your new job..." fill growVertically={true} value={description} onChange={(e) => setDescription(e.target.value)} />
                </FormGroup>
                <Row>
                    <Col>
                        <FormGroup label="Tax Exempt">
                            <RadioGroup inline onChange={({ target }: any) => setTaxExempt(target.value === 'true')} selectedValue={taxExempt ? 'true' : 'false'}>
                                <Radio label="Yes" value="true" />
                                <Radio label="No" value="false" />
                            </RadioGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label="Vendor Discounts">
                            <RadioGroup inline onChange={({ target }: any) => setVendorDiscounts(target.value === 'true')} selectedValue={vendorDiscounts ? 'true' : 'false'}>
                                <Radio label="Yes" value="true" />
                                <Radio label="No" value="false" />
                            </RadioGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup label="Document Template">
                    <DocumentTemplateSelector templateId={documentTemplateId} onChange={onDocumentTemplateChange} hideLabel small />
                </FormGroup>
                <FormGroup label="Milestone Template">
                    <MilestoneTemplateSelector templateId={milestoneTemplateId} onChange={onMilestoneTemplateChange} hideLabel small />
                </FormGroup>
                <FormGroup label="Jobsite Location" labelInfo="*">
                    <PlaceSelector initialValue={location} onChange={onLocationChange} />
                </FormGroup>
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        Job with job number {jobNumber} already exists
                    </p>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={closeDialog}>Close</Button>
                    <Button
                        onClick={saveProject}
                        intent={Intent.PRIMARY}
                        loading={isSaving}
                        disabled={name.length < 3 || !clientId || jobNumber.length === 0 || division.length === 0 || description.length === 0 || location.address.length === 0}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
