import React from 'react';
import Select from 'react-select';
import { Label } from '@blueprintjs/core';
import { getMilestoneTemplates } from '../../../api/milestones';
import { IMilestoneTemplate } from '../../../api/models/Milestone';

interface IMilestoneTemplateSelectorProps {
    onChange: (value: IMilestoneTemplate | null) => void;
    hideLabel?: boolean;
    small?: boolean;
    templateId: string | null;
    disabled?: boolean;
    placeholder?: string;
}

export const MilestoneTemplateSelector: React.FC<IMilestoneTemplateSelectorProps> = ({
    onChange,
    placeholder = 'Select a Template...',
    disabled = false,
    templateId,
    small = false,
    hideLabel = false,
}) => {
    const [value, setValue] = React.useState<IMilestoneTemplate | null>(null);
    const [templates, setTemplates] = React.useState<IMilestoneTemplate[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        loadTemplates();
    }, []);

    const loadTemplates = async () => {
        setLoading(true);
        try {
            const data = await getMilestoneTemplates();
            setTemplates(data);
        } catch (err) {
            console.log('error loadings codes: ', err.toLocaleString());
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (templateId) {
            const selected = templates.find((template) => template.id === templateId) || null;
            setValue(selected);
        } else {
            setValue(null);
        }
    }, [templates]);

    const onSelect = (job: IMilestoneTemplate | null) => {
        setValue(job);
        onChange(job);
    };
    return (
        <div>
            {!hideLabel && <Label className="text-bold">Milestone Template</Label>}
            <Select
                isClearable
                isLoading={loading}
                isDisabled={disabled}
                className={`basic-single ${small ? 'single-small' : ''}`}
                placeholder={placeholder}
                classNamePrefix="react-select"
                formatOptionLabel={(o) => {
                    return <div>{o.name}</div>;
                }}
                getOptionLabel={(o) => `${o.name}`}
                getOptionValue={(o) => o.id}
                value={value}
                isSearchable
                options={templates}
                onChange={(value: any) => {
                    onSelect(value ? value : null);
                }}
            />
        </div>
    );
};
