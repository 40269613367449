import React from 'react';
import { Classes, Button, FormGroup, InputGroup, Intent, Colors } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createOrganizationContact, removeOrganizationContact, updateOrganizationContact } from 'src/api/organization';
import { IContact, IOrganizationClient } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';
import { BottomToast } from 'src/components/Toasts';
import { ClientSelector } from '../../../../components/Selectors/ClientSelector';
import { ConfirmPopover } from '../../../../components/ConfirmPopover';

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required().min(1, 'First Name Required').label('First Name'),
    last_name: Yup.string().required().min(1, 'Last Name Required').label('Last Name'),
    phone: Yup.string().required(),
    email: Yup.string().required(),
    //client: Yup.object().required(),
});

const initialValues: Pick<IContact, 'id' | 'first_name' | 'last_name' | 'phone' | 'email' | 'client'> = {
    id: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    client: null,
};

interface ICreateRoleForm {
    selectedContact: IContact | null;
    setCreateContactDialogOpen: (isOpen: boolean, contact?: IContact) => void;
    clientOptions?: IOrganizationClient[];
}

export const CreateContactForm: React.FC<ICreateRoleForm> = ({ selectedContact, setCreateContactDialogOpen, clientOptions }) => {
    const { onContactCreated, onContactUpdated, onContactDeleted } = useOrganization();
    const [error, setError] = React.useState<string | null>(null);
    const [deleting, setDeleting] = React.useState(false);
    const closeDialog = () => {
        setCreateContactDialogOpen(false);
        formik.resetForm();
    };
    const formik = useFormik({
        initialValues: selectedContact ? selectedContact : initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setError(null);
            try {
                if (selectedContact) {
                    const result = await updateOrganizationContact(values);
                    onContactUpdated(result);
                    setCreateContactDialogOpen(false);
                    BottomToast.show({ message: 'Contact successfully updated!', intent: 'success', icon: 'updated' });
                } else {
                    const result = await createOrganizationContact(values);
                    onContactCreated(result);
                    setCreateContactDialogOpen(false);
                    BottomToast.show({ message: 'Contact successfully created!', intent: 'success', icon: 'tick-circle' });
                }
            } catch (err) {
                setError(err.message);
                BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
            }
        },
    });

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    };

    const removeContact = async () => {
        if (!selectedContact) return;
        setDeleting(true);
        try {
            await removeOrganizationContact(selectedContact.id);
            setCreateContactDialogOpen(false);
            onContactDeleted(selectedContact);
            BottomToast.show({ message: 'Contact successfully deleted!', intent: 'success', icon: 'updated' });
        } catch (err) {
            BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
        }
        setDeleting(false);
    };

    const deleteAction = (
        <ConfirmPopover
            contentTitle="Are you sure you want to remove this contact?"
            contentBody="This action cannot be reversed."
            confirmButtonText="Delete Contact"
            confirmButtonIntent="danger"
            onConfirmClick={() => removeContact()}
            interactionKind="click"
            loading={deleting}
        >
            <Button intent="danger">{deleting ? 'Deleting' : 'Delete'}</Button>
        </ConfirmPopover>
    );

    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Row>
                    <Col>
                        <FormGroup label="Customer" labelInfo="*">
                            <ClientSelector
                                onChange={(client) => {
                                    formik.setFieldValue('client', client);
                                }}
                                initialValue={formik.values.client ? formik.values.client : undefined}
                                clientOptions={clientOptions}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormGroup label="First Name" labelFor="contact-first-name-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="contact-first-name-input"
                                placeholder="First name..."
                                value={formik.values.first_name}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => formik.setFieldValue('first_name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup label="Last Name" labelFor="contact-last-name-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="contact-last-name-input"
                                placeholder="Last name..."
                                value={formik.values.last_name}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => formik.setFieldValue('last_name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup label="Phone Number" labelFor="contact-phone-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="contact-phone-input"
                                placeholder="Phone number..."
                                value={formik.values.phone}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => formik.setFieldValue('phone', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup label="Email" labelFor="contact-email-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="contact-email-input"
                                placeholder="Email..."
                                value={formik.values.email}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        {error}
                    </p>
                )}
            </div>
            <div className="m-b-20">
                <div className={Classes.DIALOG_FOOTER}>
                    <Row>
                        <Col>{selectedContact && deleteAction}</Col>
                        <Col className="text-right">
                            <Button onClick={closeDialog} className="m-r-10">
                                Cancel
                            </Button>
                            <Button onClick={() => formik.handleSubmit()} intent={Intent.PRIMARY} loading={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
                                {selectedContact ? 'Save Changes' : 'Create Contact'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
