import React from 'react';
import { LinearProgress, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Row, Col } from 'react-grid-system';
import { PostCard } from 'src/routes/Jobs/ActivityFeed/components/PostCard';
import { PostItem } from 'src/routes/Jobs/ActivityFeed/components/PostItem';
import { EditPost } from 'src/routes/Jobs/ActivityFeed/components/EditPost';
import { Filters } from 'src/routes/Jobs/ActivityFeed/components/Filters';
import { loadPosts } from '../../../api/posts';
import { PostResponse, PostResponseMeta } from 'src/api/models/Post';
import { PostViewDialog } from 'src/routes/Jobs/ActivityFeed/components/PostViewDialog';
import { useParams, useLocation, useHistory } from 'react-router-dom';

const initialPage = {
    current_page: 1,
    first_page: 0,
    last_page: 2,
    per_page: 25,
    total: 0,
};

export const ActivityFeed: React.FC<{ jobId: number }> = ({ jobId }) => {
    const { id }: any = useParams();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [page, setPage] = React.useState<PostResponseMeta>(initialPage);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [postViewOpen, setPostViewOpen] = React.useState(false);
    const [sort, setSort] = React.useState('updated_at');
    const [filter, setFilter] = React.useState('all');
    const [postType, setPostType] = React.useState('all');
    const [query, setQuery] = React.useState('');
    const [posts, setPosts] = React.useState<PostResponse[]>([]);
    const [openPost, setOpenPost] = React.useState<PostResponse | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [filters, setFilters] = React.useState({
        visibility: 'all',
        resolved: 'unresolved',
        ctc: true,
        assignedToMe: false,
        createdByMe: false,
        constraint: false,
        critical: false,
        cost: false,
        assigned: false,
    });

    const onFilterChange = (key: string, value: string | boolean) => {
        setFilters((prevState) => {
            return {
                ...prevState,
                [key]: value,
            };
        });
    };
    React.useEffect(() => {
        reset();
    }, [jobId, sort, filters, postType, query]);

    React.useEffect(() => {
        if (params.get('postId')) {
            params.delete('postId');
            history.replace(location.pathname);
            setPostViewOpen(true);
        }
    }, [params]);

    React.useEffect(() => {
        if (pageNumber > 1) {
            fetchPosts(pageNumber);
        }
    }, [pageNumber]);
    const fetchPosts = async (nextPage: number, load = true) => {
        if (!id) return;
        setLoading(nextPage === 1);
        const data = await loadPosts(jobId, sort, filters, postType, query, nextPage);
        if (nextPage === 1) {
            setPosts(data.data);
        } else {
            setPosts((prevState) => [...prevState, ...data.data]);
        }

        setPage(data.meta);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const openEditDialog = (item: PostResponse) => {
        setOpenPost(item);
    };

    const closeEditDialog = () => {
        setOpenPost(null);
    };

    const renderPosts = () => {
        if (posts.length === 0) {
            return <h3 className="text-center">No posts matching your filters</h3>;
        }
        return posts.map((post) => {
            return <PostItem key={post.id} item={post} refreshPosts={reset} openEditDialog={openEditDialog} />;
        });
    };

    const openEditFromView = (item: PostResponse) => {
        params.delete('postId');
        history.replace(location.pathname);
        setPostViewOpen(false);
        openEditDialog(item);
    };

    const closePostView = () => {
        setPostViewOpen(false);
    };

    const reset = () => {
        if (pageNumber === 1) {
            fetchPosts(1);
        } else {
            setPageNumber(1);
            fetchPosts(1);
        }
    };
    return (
        <div>
            <PostViewDialog onClose={() => closePostView()} jobId={jobId} postId={params.get('postId')} open={postViewOpen} openEditDialog={openEditFromView} />
            <PostCard onPostCreated={reset} />
            <Filters
                filters={filters}
                sort={sort}
                onFiltersChange={onFilterChange}
                onSortChange={(value) => setSort(value)}
                postType={postType}
                onPostTypeChange={(value) => setPostType(value)}
                filter={filter}
                query={query}
                onFilterChange={(value) => {
                    setFilter(value);
                }}
                onQueryChange={(value) => setQuery(value)}
            />
            <EditPost post={openPost} handleClose={closeEditDialog} onPostSaved={reset} />
            <Row justify="center">
                <Col xs={12} xl={6}>
                    {loading ? (
                        <div className="text-center m-t-0">
                            <LinearProgress />
                        </div>
                    ) : (
                        <>
                            <InfiniteScroll
                                dataLength={posts.length} //This is important field to render the next data
                                next={() => setPageNumber((prevState) => prevState + 1)}
                                hasMore={posts.length > 0 && page.last_page >= page.current_page}
                                loader={
                                    <div className="text-center m-t-15">
                                        <CircularProgress />
                                    </div>
                                }
                                //endMessage={<p className="text-center text-bold">You have seen it all!</p>}
                                // below props only if you need pull down functionality
                                refreshFunction={() => fetchPosts(pageNumber)}
                                pullDownToRefresh={false}
                                pullDownToRefreshThreshold={50}
                                pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
                                releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
                            >
                                {renderPosts()}
                            </InfiniteScroll>

                            {/*{posts.length > 0 && page.last_page >= page.current_page && (*/}
                            {/*    <Button className="m-t-15" onClick={() => setPageNumber((prevState) => prevState + 1)}>*/}
                            {/*        Next*/}
                            {/*    </Button>*/}
                            {/*)}*/}
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};
