import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Header } from '../../../components/Header';
import { Button, Spinner } from '@blueprintjs/core';
import { CreateContactDialog } from './components/CreateContactDialog';
import { useOrganization } from 'src/hooks/useOrganization';
import { FiretrolTable } from '../../../components/FiretrolTable';
import { IContact } from '../../../api/models/Organization';

const getColumns = (setCreateClientDialogOpen: (isOpen: boolean, contact: IContact | null) => void) => {
    return [
        {
            name: 'First',
            selector: (contact: IContact) => contact.first_name,
            sortable: true,
        },
        {
            name: 'Last',
            selector: (contact: IContact) => contact.last_name,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (contact: IContact) => contact.phone,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (contact: IContact) => contact.email,
            sortable: true,
        },
        {
            name: 'Company',
            selector: (contact: IContact) => contact.client?.name || '',
            sortable: true,
        },
        {
            name: '',
            format: (contact: IContact) => <Button text="Edit" small minimal intent="primary" onClick={() => setCreateClientDialogOpen(true, contact)} />,
            selector: (contact: IContact) => contact.id,
        },
    ];
};
export const Contacts: React.FC = () => {
    const [createContactOpen, setCreateContactOpen] = React.useState<{ isOpen: boolean; contact: IContact | null }>({
        isOpen: false,
        contact: null,
    });

    const { loading, contacts } = useOrganization();
    const setContactOpen = (isOpen: boolean, contact: IContact | null) => {
        setCreateContactOpen({ isOpen, contact });
    };
    const columns = React.useMemo(() => getColumns(setContactOpen), [setContactOpen]);
    return (
        <>
            <Header
                title="Contacts"
                breadcrumbs={['Settings', 'Contacts']}
                actionComponent={<Button text="Create Contact" onClick={() => setContactOpen(true, null)} intent="primary" icon="new-person" />}
            />
            <CreateContactDialog open={createContactOpen.isOpen} onClose={() => setContactOpen(false, null)} contact={createContactOpen.contact} />
            <Row className="m-b-15">
                <Col>{loading ? <Spinner /> : <FiretrolTable columns={columns} data={contacts} />}</Col>
            </Row>
        </>
    );
};
