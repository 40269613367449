import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Button, Card, Dialog, FormGroup, InputGroup, Spinner } from '@blueprintjs/core';
import { useParams, useHistory } from 'react-router-dom';
import { FolderTree } from '../FolderTree';
import { IDefaultFolder } from '../../../../api/models/DefaultFolder';
import { getFolderTemplateById, removeTemplateFolder, updateFolderTemplate, deleteFolderTemplate } from '../../../../api/folders';
import { CreateFolderDialog } from '../CreateFolderDialog';
import { Header } from '../../../../components/Header';
import { AppToast } from '../../../../components/Toasts/AppToast';
import { ConfirmPopover } from '../../../../components/ConfirmPopover';

export const CreateDocumentTemplate: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [folders, setFolders] = React.useState<IDefaultFolder[]>([]);
    const [createFolderOpen, setCreateFolderOpen] = React.useState(false);
    const [editFolderOpen, setEditFolderOpen] = React.useState(false);
    const [selectedFolder, setSelectedFolder] = React.useState<IDefaultFolder | null>(null);
    const [breadcrumbs, setBreadcrumbs] = React.useState<IDefaultFolder[]>([]);
    const [name, setName] = React.useState('');
    const [tname, setTname] = React.useState('');
    // const [documents, setDocuments] = React.useState<IDefaultFolder[]>([]);

    React.useEffect(() => {
        loadTemplate();
    }, [params.id]);

    const loadTemplate = async (load = true) => {
        setLoading(load);
        setError(null);
        setSelectedFolder(null);
        try {
            const data = await getFolderTemplateById(params.id);
            setName(data.template.name);
            setTname(data.template.name);
            setFolders(data.folders);
        } catch (err) {
            setError(err.toString());
        }
        setLoading(false);
    };

    const closeCreateDialog = () => {
        setCreateFolderOpen(false);
    };

    const closeEditDialog = () => {
        setEditFolderOpen(false);
    };

    const onFolderSelected = (id: string, path: number[]) => {
        if (id === 'documents') {
            setSelectedFolder(null);
            return setBreadcrumbs([]);
        }
        if (!id) {
            setSelectedFolder(null);
            return setBreadcrumbs([]);
        }

        const crumbs: IDefaultFolder[] = [];
        path.forEach((location, idx) => {
            if (idx === 0) {
                crumbs.push(folders[location]);
            } else {
                crumbs.push(crumbs[idx - 1].children[location]);
            }
        });
        const parentIdx = crumbs.length - 1;
        const parent = crumbs[parentIdx] || null;
        setSelectedFolder(parent);
        setBreadcrumbs(crumbs);
    };

    const removeFolder = async () => {
        if (!selectedFolder) return;
        await removeTemplateFolder(params.id, selectedFolder.id);
        setSelectedFolder(null);
        setBreadcrumbs([]);
        loadTemplate(false);
    };

    const updateTemplate = async () => {
        await updateFolderTemplate(params.id, { name });
        AppToast.show({ message: `"${name}" updated!`, intent: 'success', icon: 'tick-circle' });
    };
    const deleteTemplate = async () => {
        await deleteFolderTemplate(params.id);
        AppToast.show({ message: `"${name}" deleted!`, intent: 'success', icon: 'tick-circle' });
        history.push('/settings/documents');
    };
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            updateTemplate();
        }
    };

    const lockButton = <Button icon="tick" intent="primary" text="Save" onClick={updateTemplate} disabled={tname === name} />;
    return (
        <>
            <Header
                title="Documents"
                breadcrumbs={['Settings', 'Documents', 'Create Template']}
                goBackIndices={[1]}
                goBackPath={`/settings/documents`}
                actionComponent={
                    <>
                        <Button small text="Back" onClick={() => history.push('/settings/documents')} className="m-t-5 m-l-5" />
                        <ConfirmPopover
                            contentTitle="Are you sure?"
                            confirmButtonIntent="danger"
                            contentBody={<p>Are you sure you want to remove this template? This will not have an effect on any jobs created with this template.</p>}
                            confirmButtonText="Delete"
                            onConfirmClick={deleteTemplate}
                        >
                            <Button small text="Delete Template" className="m-t-5 m-l-5" intent="danger" />
                        </ConfirmPopover>
                    </>
                }
            />
            <Row>
                <Col xs={12}>
                    <Card className="m-b-15">
                        <Row>
                            <Col>
                                <FormGroup label="Template Name">
                                    <InputGroup onKeyDown={handleKeyDown} rightElement={lockButton} placeholder="Enter template name" onChange={(e) => setName(e.target.value)} fill value={name} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ minHeight: 500 }}>
                        <Row>
                            <Col>
                                <h3 className="m-t-0">Default Folders</h3>
                            </Col>
                            <Col xs={8} className="text-right">
                                {selectedFolder ? (
                                    <>
                                        <Button small onClick={() => setCreateFolderOpen(true)}>
                                            Add Subfolder to <b>{selectedFolder.name}</b>
                                        </Button>{' '}
                                        <Button small onClick={() => setEditFolderOpen(true)}>
                                            Edit <b>{selectedFolder.name}</b>
                                        </Button>{' '}
                                        <Button intent="danger" minimal small icon="trash" onClick={removeFolder} />
                                    </>
                                ) : (
                                    <Button small onClick={() => setCreateFolderOpen(true)}>
                                        Create Folder
                                    </Button>
                                )}
                            </Col>
                        </Row>

                        {error && <p>{error}</p>}
                        {loading ? (
                            <div className="p-t-50 text-center">
                                <Spinner />
                            </div>
                        ) : (
                            <FolderTree selectedFolder={selectedFolder} onFolderSelected={onFolderSelected} folders={folders} />
                        )}
                    </Card>
                </Col>
                <Dialog isOpen={createFolderOpen} onClose={closeCreateDialog} title="Create Folder" icon="folder-new">
                    {createFolderOpen && (
                        <CreateFolderDialog breadcrumbs={breadcrumbs} onClose={closeCreateDialog} selectedFolder={selectedFolder} templateId={params.id} onSaveComplete={() => loadTemplate()} />
                    )}
                </Dialog>
                <Dialog isOpen={editFolderOpen} onClose={closeEditDialog} title="Edit Folder" icon="folder-close">
                    {editFolderOpen && (
                        <CreateFolderDialog edit templateId={params.id} breadcrumbs={breadcrumbs} onClose={closeEditDialog} selectedFolder={selectedFolder} onSaveComplete={() => loadTemplate()} />
                    )}
                </Dialog>
            </Row>
        </>
    );
};
