import React from 'react';
import Select, { Props } from 'react-select';
import { listJobPhases, listPhases } from 'src/api/checklists';

interface IPhase {
    id: string;
    title: string;
}

interface IPhaseSelectorProps extends Props {
    value: IPhase | null;
    jobId?: number;
    templateId?: string;
}

export const PhaseSelector: React.FC<IPhaseSelectorProps> = ({ value, onChange, jobId, templateId }) => {
    const [options, setOptions] = React.useState<IPhase[]>([]);
    React.useEffect(() => {
        loadPhases();
    }, []);
    const loadPhases = async () => {
        try {
            if (jobId) {
                const data = await listJobPhases(jobId);
                setOptions(data);
            } else {
                const data = await listPhases();
                if (templateId) {
                    setOptions(data.filter((d) => d.template_id === templateId));
                } else {
                    setOptions(data);
                }
            }
        } catch (err) {
            console.log('error: ', err.toLocaleString());
        }
    };
    return (
        <>
            <Select
                isClearable
                className="basic-single"
                placeholder="Select Phase..."
                classNamePrefix="react-select"
                getOptionLabel={(o: IPhase) => o.title}
                getOptionValue={(o: IPhase) => o.id}
                value={value}
                options={options}
                onChange={onChange}
            />
        </>
    );
};
