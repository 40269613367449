import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { CreateContactForm } from './CreateContactForm';
import { IContact, IOrganizationClient } from 'src/api/models/Organization';

interface CreateContactDialogProps {
    open: boolean;
    onClose: () => void;
    contact: IContact | null;
    clientOptions?: IOrganizationClient[];
}

export const CreateContactDialog: React.FC<CreateContactDialogProps> = ({ open, onClose, contact, clientOptions }) => {
    return (
        <Dialog isOpen={open} onClose={onClose} title={contact ? 'Update Contact' : 'Create New Contact'} icon="id-number">
            <CreateContactForm setCreateContactDialogOpen={onClose} selectedContact={contact} clientOptions={clientOptions} />
        </Dialog>
    );
};
