import React from 'react';
import { Row, Col } from 'react-grid-system';
import { IJob } from '../../../api/models/Job';
import { FormGroup, InputGroup, TextArea, Button, Colors, RadioGroup, Radio } from '@blueprintjs/core';
import { ClientSelector } from '../../../components/Selectors/ClientSelector';
import { PlaceSelector } from '../../../components/Selectors/PlaceSelector';
import { updateJob } from 'src/api/jobs';
import { AppToast } from '../../../components/Toasts/AppToast';
import { CTCJobSelector } from '../../../components/Selectors/CTCJobSelector/single';
import { CTCJob } from '../../../api/models/CTC';
interface EditDetailsProps {
    job: IJob;
    onCancel: () => void;
    onSave: () => void;
}

export const EditDetails: React.FC<EditDetailsProps> = ({ job, onCancel, onSave }) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [values, setValues] = React.useState({
        name: job.name,
        description: job.description,
        job_number: job.job_number,
        client: job.client,
        client_id: job.client_id,
        location: job.location,
        division: job.division,
        tax_exempt: job.tax_exempt,
        vendor_discounts: job.vendor_discounts,
    });

    const updateField = (field: string, value: any) => {
        setValues((prevState) => {
            return {
                ...prevState,
                [field]: value,
            };
        });
    };

    const updateJobDetails = async () => {
        setIsSaving(true);
        setError(null);
        try {
            await updateJob(job.id, values);
            AppToast.show({ message: `Job details updated!`, intent: 'success', icon: 'tick' });
            onSave();
            onCancel();
        } catch (err) {
            setError(err.toLocaleString());
        }

        setIsSaving(false);
    };
    const onCTCJobChange = (job: CTCJob | null) => {
        if (job) {
            setValues((prevState) => {
                return {
                    ...prevState,
                    name: job.ws_job_name,
                    job_number: job.ws_job_number,
                    division: job.division,
                };
            });
        } else {
            setValues((prevState) => {
                return {
                    ...prevState,
                    name: '',
                    job_number: '',
                    division: '',
                };
            });
        }
    };

    const getType = (v: string) => {
        const items = v.split('-');
        return items[1] ? items[1] : 'N/A';
    };
    return (
        <>
            <FormGroup label="CTC Job" labelInfo="*">
                <CTCJobSelector ctcJobId={values.job_number} onChange={onCTCJobChange} hideLabel small />
            </FormGroup>
            <FormGroup label="Customer" labelInfo="*">
                <ClientSelector
                    initialValue={values.client}
                    onChange={(client) => {
                        if (client) {
                            updateField('client', client);
                            updateField('client_id', client.id);
                        }
                    }}
                />
            </FormGroup>
            <FormGroup label="Division">
                <InputGroup placeholder="Derived from CTC Job" fill value={values.division} disabled />
            </FormGroup>
            <FormGroup label="Job Type">
                <InputGroup placeholder="Derived from CTC Job" fill value={getType(values.division)} disabled />
            </FormGroup>
            <FormGroup label="Scope of Work">
                <TextArea placeholder="Enter a scope of work..." fill growVertically={true} value={values.description} onChange={(e) => updateField('description', e.target.value)} />
            </FormGroup>
            <Row>
                <Col>
                    <FormGroup label="Tax Exempt">
                        <RadioGroup inline onChange={({ target }: any) => updateField('tax_exempt', target.value === 'true')} selectedValue={values.tax_exempt ? 'true' : 'false'}>
                            <Radio label="Yes" value="true" />
                            <Radio label="No" value="false" />
                        </RadioGroup>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup label="Vendor Discounts">
                        <RadioGroup inline onChange={({ target }: any) => updateField('vendor_discounts', target.value === 'true')} selectedValue={values.vendor_discounts ? 'true' : 'false'}>
                            <Radio label="Yes" value="true" />
                            <Radio label="No" value="false" />
                        </RadioGroup>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup label="Jobsite Location">
                <PlaceSelector initialValue={values.location} onChange={(location) => updateField('location', location)} />
            </FormGroup>
            {error && <p style={{ color: Colors.RED5 }}>Job with job number {values.job_number} already exists</p>}
            <div className="text-right">
                <Button onClick={onCancel}>Cancel</Button>{' '}
                <Button onClick={updateJobDetails} disabled={values.job_number.length === 0} intent="primary" loading={isSaving}>
                    Save
                </Button>
            </div>
        </>
    );
};
