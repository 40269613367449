import AppApi from './index';
import { IOrganization, IOrganizationRole, IOrganizationOffice, IOrganizationClient, IContact } from './models/Organization';
import { IDailyQuestionResponse, IDailyQuestionFormValues } from './models/Daily';
import { getAuthOptions } from './helpers';

const api = AppApi.getApi();

export const loadOrganization = async (): Promise<IOrganization> => {
    const url = `/organization`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadOrganizationRoles = async (): Promise<IOrganizationRole[]> => {
    const url = `/organization/roles`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createOrganizationRole = async (role: IOrganizationRole): Promise<IOrganizationRole> => {
    const url = `/organization/roles`;
    const { data } = await api.post(url, role, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const updateOrganizationRole = async (role: IOrganizationRole): Promise<IOrganizationRole> => {
    const url = `/organization/roles/${role.id}`;
    const { data } = await api.put(url, role, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const deleteOrganizationRole = async (role: IOrganizationRole): Promise<IOrganizationRole> => {
    const url = `/organization/roles/${role.id}`;
    const { data } = await api.delete(url, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const loadOrganizationOffices = async (): Promise<IOrganizationOffice[]> => {
    const url = `/organization/offices`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createOrganizationOffice = async (office: Pick<IOrganizationOffice, 'id' | 'name' | 'description'>): Promise<IOrganizationOffice> => {
    const url = `/organization/offices`;
    const { data } = await api.post(url, office, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const updateOrganizationOffice = async (office: Pick<IOrganizationOffice, 'id' | 'name' | 'description'>): Promise<IOrganizationOffice> => {
    const url = `/organization/offices/${office.id}`;
    const { data } = await api.put(url, office, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const deleteOrganizationOffice = async (office: Pick<IOrganizationOffice, 'id' | 'name' | 'description'>): Promise<IOrganizationOffice> => {
    const url = `/organization/offices/${office.id}`;
    const { data } = await api.delete(url, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const loadOrganizationContacts = async (): Promise<IContact[]> => {
    const url = `/organization/contacts`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createOrganizationContact = async (contact: Pick<IContact, 'id' | 'first_name' | 'last_name' | 'phone' | 'email' | 'client'>): Promise<IContact> => {
    const url = `/organization/contacts`;
    const { data } = await api.post(url, contact, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const removeOrganizationContact = async (id: string): Promise<IContact> => {
    const url = `/organization/contacts/${id}`;
    const { data } = await api.delete(url, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};
export const updateOrganizationContact = async (contact: Pick<IContact, 'id' | 'first_name' | 'last_name' | 'phone' | 'email' | 'client'>): Promise<IContact> => {
    const url = `/organization/contacts/${contact.id}`;
    const { data } = await api.put(url, contact, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const loadOrganizationClients = async (): Promise<IOrganizationClient[]> => {
    const url = `/organization/clients`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const searchOrganizationClients = async (q: string): Promise<IOrganizationClient[]> => {
    const url = `/organization/clients?query=${q}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadOrganizationClientById = async (id: number): Promise<IOrganizationClient> => {
    const url = `/organization/clients/${id}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createOrganizationClient = async (client: Pick<IOrganizationClient, 'id' | 'name' | 'description'>): Promise<IOrganizationClient> => {
    const url = `/organization/clients`;
    const { data } = await api.post(url, client, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const updateOrganizationClient = async (client: Pick<IOrganizationClient, 'id' | 'name' | 'description'>): Promise<IOrganizationClient> => {
    const url = `/organization/clients/${client.id}`;
    const { data } = await api.put(url, client, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const deleteOrganizationClient = async (client: Pick<IOrganizationClient, 'id' | 'name' | 'description'>): Promise<IOrganizationClient> => {
    const url = `/organization/client/${client.id}`;
    const { data } = await api.delete(url, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const listOrganizationQuestions = async (): Promise<IDailyQuestionResponse[]> => {
    const url = `/organization/dailys/questions`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createOrganizationQuestion = async (question: IDailyQuestionFormValues): Promise<IDailyQuestionResponse> => {
    const url = `/organization/dailys/questions`;
    const { data } = await api.post(url, question, await getAuthOptions());
    return data;
};

export const updateOrganizationQuestion = async (questionId: number, question: IDailyQuestionFormValues): Promise<IDailyQuestionResponse> => {
    const url = `/organization/dailys/questions/${questionId}`;
    const { data } = await api.put(url, question, await getAuthOptions());
    return data;
};

export const removeOrganizationQuestion = async (questionId: number): Promise<IDailyQuestionResponse> => {
    const url = `/organization/dailys/questions/${questionId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
