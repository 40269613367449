import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, Card } from '@blueprintjs/core';
import { useJobs } from 'src/hooks/useJobs';
import { IEnhancedPhase } from 'src/api/models/Checklist';
import { ChecklistItemDialog } from 'src/components/Dialogs/ChecklistItemDialog';
import { listItems, listEnhancedJobPhases, removePhase } from 'src/api/checklists';
import { IChecklistItem } from 'src/api/models/Checklist';
import { ItemTable } from './ItemTable';
import { Header2 } from '../../../components/Header2';
import { BottomToast } from '../../../components/Toasts';
import { ConfirmPopover } from '../../../components/ConfirmPopover';

export const Checklists: React.FC = () => {
    const { selectedJob } = useJobs();
    // const { dialogOpen, setDialogOpen } = useJobTasks();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<IChecklistItem | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [items, setItems] = React.useState<IChecklistItem[]>([]);
    const [phases, setPhases] = React.useState<IEnhancedPhase[]>([]);
    const [selectedPhaseId, setSelectedPhaseId] = React.useState<string | null>(null);

    React.useEffect(() => {
        loadItems();
    }, [selectedPhaseId]);

    const loadItems = async (load = true) => {
        if (!selectedJob) return;
        setError(null);
        setLoading(load);
        try {
            const ph = await listEnhancedJobPhases(selectedJob.id);
            setPhases(ph);
            const data = await listItems(selectedJob.id, { phase: selectedPhaseId });
            setItems(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const remove = async (id: string) => {
        if (!selectedJob) return;
        await removePhase(id);
        const ph = await listEnhancedJobPhases(selectedJob.id);
        setPhases(ph);
        BottomToast.show({ message: 'Phase removed!', intent: 'success', icon: 'tick-circle' });
        setTimeout(() => {
            setSelectedPhaseId(null);
        }, 1000);
    };

    const renderPhases = () => {
        return phases.map((phase) => {
            const percentage = phase.target_items.length === 0 ? 0 : (phase.completed_items.length / phase.target_items.length) * 100;
            const isSelected = selectedPhaseId === phase.id;
            return (
                <Col xs={12} md={3} key={phase.id} className="m-b-15">
                    <Card
                        interactive
                        style={{ backgroundColor: isSelected ? '#e9e9e9' : '#fff', position: 'relative' }}
                        onClick={() => (isSelected ? setSelectedPhaseId(null) : setSelectedPhaseId(phase.id))}
                        elevation={isSelected ? 3 : 0}
                    >
                        <div style={{ position: 'absolute', top: 5, right: 5 }}>
                            <ConfirmPopover
                                contentTitle="Are you sure you want to remove this phase?"
                                contentBody="All checklist items for this phase will also be removed. This action cannot be reversed."
                                confirmButtonText="Delete Phase"
                                confirmButtonIntent="danger"
                                onConfirmClick={() => remove(phase.id)}
                                interactionKind="click"
                                loading={false}
                            >
                                <Button icon="trash" minimal />
                            </ConfirmPopover>
                        </div>

                        <h4 className="m-0">
                            {phase.title} - {Math.round(percentage)}% complete
                        </h4>
                        <div className="bp3-progress-bar bp3-no-stripes bp3-no-animation bp3-intent-primary m-t-10">
                            <div className="bp3-progress-meter" style={{ width: `${percentage}%` }} />
                        </div>
                    </Card>
                </Col>
            );
        });
    };
    if (!selectedJob) return null;
    return (
        <div>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Milestones', link: '' },
                ]}
                actionComponent={
                    <Button intent="primary" icon="add" onClick={() => setDialogOpen(true)}>
                        Add Item
                    </Button>
                }
            />
            {error && <p>{error}</p>}
            <Row>{renderPhases()}</Row>
            <ItemTable data={items} onItemUpdated={() => loadItems(false)} onRowClick={(item) => setSelectedItem(item)} loading={loading} />
            <ChecklistItemDialog
                selectedJobId={selectedJob.id}
                isOpen={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onCreateComplete={() => loadItems(false)}
                onUpdateComplete={() => loadItems(false)}
            />
            {selectedItem && (
                <ChecklistItemDialog
                    data={selectedItem}
                    isOpen={selectedItem !== null}
                    onClose={() => setSelectedItem(null)}
                    onCreateComplete={() => loadItems(false)}
                    onUpdateComplete={() => loadItems(false)}
                    selectedJobId={selectedJob.id}
                />
            )}
        </div>
    );
};
