import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { InputGroup, Menu, MenuItem } from '@blueprintjs/core';
import { ILocation } from 'src/api/models/Job';
import './index.scss';

interface IPlaceSelectorProps {
    initialValue: ILocation;
    onChange: (location: ILocation) => void;
}

export const PlaceSelector: React.FC<IPlaceSelectorProps> = ({ initialValue, onChange }) => {
    const [location, setLocation] = React.useState<ILocation>(initialValue);
    React.useEffect(() => {
        if (location.geo !== null) {
            onChange(location);
        }
    }, [location]);

    const onSelect = async (add: string) => {
        const results = await geocodeByAddress(add);
        const latlong = await getLatLng(results[0]);
        setLocation({ address: add, geo: latlong });
    };

    return (
        <>
            <PlacesAutocomplete value={location.address} onChange={(value) => setLocation((prev) => ({ ...prev, address: value }))} onSelect={onSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                    console.log('suggestion: ', suggestions);
                    return (
                        <div style={{ position: 'relative' }}>
                            <InputGroup
                                {...getInputProps({
                                    placeholder: 'Search location ...',
                                    className: 'location-search-input',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && (
                                    <Menu>
                                        <MenuItem text="Loading..." />
                                    </Menu>
                                )}
                                {suggestions.length > 0 && (
                                    <Menu>
                                        {suggestions.map((suggestion) => {
                                            return <MenuItem active={suggestion.active} text={suggestion.description} {...getSuggestionItemProps(suggestion)} />;
                                        })}
                                    </Menu>
                                )}
                            </div>
                        </div>
                    );
                }}
            </PlacesAutocomplete>
        </>
    );
};
