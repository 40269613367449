import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Spinner, Tag, NonIdealState } from '@blueprintjs/core';
import Button from '@mui/material/Button';
import { useJobs } from 'src/hooks/useJobs';
// import './index.scss';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

interface TeamCardProps {
    jobId: number;
}

export const TeamCard: React.FC<TeamCardProps> = ({ jobId }) => {
    const { team, fetchTeam, loadingTeam } = useJobs();
    const history = useHistory();

    React.useEffect(() => {
        fetchTeam();
    }, []);

    const renderTeam = () => {
        if (team.length === 0) {
            return (
                <div className="no-items-container">
                    <NonIdealState title="No Team Members" icon="people" description="No team members have been added to this job" />
                </div>
            );
        }
        return team.map((t) => {
            return (
                <div key={t.id} className="team-member-row">
                    <Row>
                        <Col>
                            <p>
                                {t.user.first_name} {t.user.last_name}
                            </p>
                        </Col>
                        <Col>
                            <span>
                                {t.user.roles.map((r) => {
                                    return (
                                        <Tag intent="primary" className="m-r-5">
                                            {r.name}
                                        </Tag>
                                    );
                                })}
                            </span>
                        </Col>
                    </Row>
                </div>
            );
        });
    };
    return (
        <Card sx={{ mb: 3, pb: 2 }}>
            <CardHeader
                action={
                    <Button size="small" sx={{ mt: 1 }} onClick={() => history.push(`/jobs/${jobId}/team`)}>
                        View All
                    </Button>
                }
                className="p-b-5"
                titleTypographyProps={{ variant: 'h6' }}
                title="Project Team"
            />
            <CardContent style={{ height: 200, overflow: 'auto', paddingBottom: 10 }}>{loadingTeam ? <Spinner /> : <>{renderTeam()}</>}</CardContent>
        </Card>
    );
};
