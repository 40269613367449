import { IMilestoneTemplate, IMilestoneTemplateResponse } from './models/Milestone';
import { getAuthOptions } from './helpers';
import AppApi from './index';
const api = AppApi.getApi();

export const createMilestoneTemplate = async (): Promise<IMilestoneTemplate> => {
    const url = `milestoneTemplates`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const updateMilestoneTemplate = async (templateId: string, payload: { name: string }): Promise<IMilestoneTemplate> => {
    const url = `milestoneTemplates/${templateId}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const deleteMilestoneTemplate = async (templateId: string): Promise<IMilestoneTemplate> => {
    const url = `milestoneTemplates/${templateId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const getMilestoneTemplates = async (): Promise<IMilestoneTemplate[]> => {
    const url = `milestoneTemplates`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const getMilestoneTemplateById = async (id: string): Promise<IMilestoneTemplateResponse> => {
    const url = `milestoneTemplates/${id}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
