import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { IContact } from '../../../api/models/Organization';
import { loadContactsByJob } from '../../../api/jobs';

interface ContactsCardProps {
    jobId: number;
}

export const ContactsCard: React.FC<ContactsCardProps> = ({ jobId }) => {
    const history = useHistory();
    const [contacts, setContacts] = React.useState<IContact[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        loadContacts();
    }, []);

    const loadContacts = async () => {
        setLoading(true);
        try {
            const data = await loadContactsByJob(jobId);
            setContacts(data);
        } catch (err) {
            console.log('error loading contacts');
        }
        setLoading(false);
    };

    const renderContacts = () => {
        if (contacts.length === 0) {
            return (
                <div className="no-items-container">
                    <NonIdealState title="No Contacts" icon="people" description="No contacts have been added to this job" />
                </div>
            );
        }
        return contacts.map((t) => {
            return (
                <div key={t.id} className="team-member-row">
                    <Row>
                        <Col>
                            <p>
                                {t.first_name} {t.last_name}
                            </p>
                        </Col>
                        <Col>
                            <p>{t.phone}</p>
                        </Col>
                        <Col>
                            <p>{t.email}</p>
                        </Col>
                    </Row>
                </div>
            );
        });
    };
    return (
        <Card sx={{ mb: 3, pb: 2 }}>
            <CardHeader
                action={
                    <Button size="small" sx={{ mt: 1 }} onClick={() => history.push(`/jobs/${jobId}/contacts`)}>
                        View All
                    </Button>
                }
                className="p-b-5"
                titleTypographyProps={{ variant: 'h6' }}
                title="Contacts"
            />
            <CardContent style={{ height: 200, overflow: 'auto', paddingBottom: 10 }}>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="p-l-10 p-r-10">
                            <Row>
                                <Col>
                                    <p>Name</p>
                                </Col>
                                <Col>
                                    <p>Phone</p>
                                </Col>
                                <Col>
                                    <p>Email</p>
                                </Col>
                            </Row>
                        </div>
                        {renderContacts()}
                    </>
                )}
            </CardContent>
        </Card>
    );
};
