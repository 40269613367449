import React, { useState, createContext } from 'react';
import { AppToast } from 'src/components/Toasts/AppToast';
import { IUser } from 'src/api/models/User';
import { IOrganization, IOrganizationRole, IOrganizationOffice, IOrganizationClient, IContact } from 'src/api/models/Organization';
import { loadOrganization, loadOrganizationOffices, loadOrganizationRoles, loadOrganizationClients, loadOrganizationContacts } from 'src/api/organization';
import { loadOrganizationUsers } from '../api/admin';
import { loadCTCDivisions } from '../api/ctc';

interface IOrganizationContext {
    organization: IOrganization | null;
    roles: IOrganizationRole[];
    offices: IOrganizationOffice[];
    users: IUser[];
    divisions: string[];
    clients: IOrganizationClient[];
    contacts: IContact[];
    loading: boolean;
    onRoleCreated: (role: IOrganizationRole) => void;
    onRoleUpdated: (role: IOrganizationRole) => void;
    onRoleDeleted: (role: IOrganizationRole) => void;
    onUserCreated: (user: IUser) => void;
    onUserUpdated: (user: IUser) => void;
    onUserDeleted: (user: IUser) => void;
    onOfficeCreated: (office: IOrganizationOffice) => void;
    onOfficeUpdated: (office: IOrganizationOffice) => void;
    onOfficeDeleted: (office: IOrganizationOffice) => void;
    onClientCreated: (client: IOrganizationClient) => void;
    onClientUpdated: (client: IOrganizationClient) => void;
    onClientDeleted: (client: IOrganizationClient) => void;
    onContactCreated: (client: IContact) => void;
    onContactUpdated: (client: IContact) => void;
    onContactDeleted: (client: IContact) => void;
    refreshUsers: () => Promise<void>;
}

export const OrganizationContext = createContext<IOrganizationContext>({
    organization: null,
    loading: true,
    roles: [],
    users: [],
    offices: [],
    clients: [],
    contacts: [],
    divisions: [],
    refreshUsers: () => Promise.resolve(),
    onRoleCreated: () => null,
    onRoleUpdated: () => null,
    onRoleDeleted: () => null,
    onUserCreated: () => null,
    onUserUpdated: () => null,
    onUserDeleted: () => null,
    onOfficeCreated: () => null,
    onOfficeUpdated: () => null,
    onOfficeDeleted: () => null,
    onClientCreated: () => null,
    onClientUpdated: () => null,
    onClientDeleted: () => null,
    onContactCreated: () => null,
    onContactUpdated: () => null,
    onContactDeleted: () => null,
});

export const OrganizationProvider: React.FC = ({ children }) => {
    const [organization, setOrganization] = useState<IOrganization | null>(null);
    const [roles, setRoles] = useState<IOrganizationRole[]>([]);
    const [offices, setOffices] = useState<IOrganizationOffice[]>([]);
    const [divisions, setDivisions] = useState<string[]>([]);
    const [clients, setClients] = useState<IOrganizationClient[]>([]);
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        fetchOrganization();
    }, []);

    const fetchOrganization = async () => {
        setLoading(true);
        try {
            const data = await loadOrganization();
            const roleData = await loadOrganizationRoles();
            const usersData = await loadOrganizationUsers();
            const officeData = await loadOrganizationOffices();
            const clientData = await loadOrganizationClients();
            const contactData = await loadOrganizationContacts();
            const divisionData = await loadCTCDivisions();
            setDivisions(divisionData);
            setUsers(usersData);
            setOrganization(data);
            setRoles(roleData);
            setOffices(officeData);
            setClients(clientData);
            setContacts(contactData);
        } catch (err) {
            AppToast.show({ message: `Error Loading Organization Data: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
        setLoading(false);
    };

    const refreshUsers = async () => {
        setLoading(true);
        try {
            const usersData = await loadOrganizationUsers();
            setUsers(usersData);
        } catch (err) {
            AppToast.show({ message: `Error Loading Organization Users: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
        setLoading(false);
    };

    const onUserCreated = (user: IUser) => {
        setUsers((prevState) => [...prevState, user]);
    };
    const onUserUpdated = (user: IUser) => {
        setUsers((prevState) =>
            prevState.map((r) => {
                if (r.id === user.id) {
                    return user;
                }
                return r;
            }),
        );
    };

    const onUserDeleted = (user: IUser) => {
        setUsers((prevState) => prevState.filter((r) => r.id !== user.id));
    };

    const onRoleCreated = (role: IOrganizationRole) => {
        setRoles((prevState) => [...prevState, role]);
    };

    const onRoleDeleted = (role: IOrganizationRole) => {
        setRoles((prevState) => prevState.filter((r) => r.id !== role.id));
    };

    const onRoleUpdated = (role: IOrganizationRole) => {
        setRoles((prevState) =>
            prevState.map((r) => {
                if (r.id === role.id) {
                    return role;
                }
                return r;
            }),
        );
    };

    const onOfficeCreated = (office: IOrganizationOffice) => {
        setOffices((prevState) => [...prevState, office]);
    };

    const onOfficeDeleted = (office: IOrganizationOffice) => {
        setOffices((prevState) => prevState.filter((r) => r.id !== office.id));
    };

    const onOfficeUpdated = (office: IOrganizationOffice) => {
        setOffices((prevState) =>
            prevState.map((r) => {
                if (r.id === office.id) {
                    return office;
                }
                return r;
            }),
        );
    };

    const onClientCreated = (client: IOrganizationClient) => {
        setClients((prevState) => [...prevState, client]);
    };

    const onClientDeleted = (client: IOrganizationClient) => {
        setClients((prevState) => prevState.filter((r) => r.id !== client.id));
    };

    const onClientUpdated = (client: IOrganizationClient) => {
        setClients((prevState) =>
            prevState.map((r) => {
                if (r.id === client.id) {
                    return client;
                }
                return r;
            }),
        );
    };

    const onContactCreated = (client: IContact) => {
        setContacts((prevState) => [...prevState, client]);
    };

    const onContactDeleted = (client: IContact) => {
        setContacts((prevState) => prevState.filter((r) => r.id !== client.id));
    };

    const onContactUpdated = (client: IContact) => {
        setContacts((prevState) =>
            prevState.map((r) => {
                if (r.id === client.id) {
                    return client;
                }
                return r;
            }),
        );
    };

    return (
        <OrganizationContext.Provider
            value={{
                organization,
                loading,
                roles,
                users,
                clients,
                contacts,
                offices,
                divisions,
                refreshUsers,
                onOfficeCreated,
                onOfficeUpdated,
                onOfficeDeleted,
                onRoleUpdated,
                onRoleCreated,
                onRoleDeleted,
                onUserCreated,
                onUserUpdated,
                onUserDeleted,
                onClientCreated,
                onClientUpdated,
                onClientDeleted,
                onContactCreated,
                onContactUpdated,
                onContactDeleted,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

export const useOrganization: () => IOrganizationContext = () => {
    const context = React.useContext(OrganizationContext);
    return context;
};
