import React from 'react';
import { IOrganizationClient } from 'src/api/models/Organization';
import { loadOrganizationClientById } from 'src/api/organization';
import { InputGroup } from '@blueprintjs/core';

interface IClientSelectorProps {
    clientId: number | null;
}

export const ClientSelector: React.FC<IClientSelectorProps> = ({ clientId }) => {
    const [value, setValue] = React.useState<IOrganizationClient | null>(null);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        loadClient();
    }, [clientId]);

    const loadClient = async () => {
        if (!clientId) return;
        setLoading(true);
        try {
            const client = await loadOrganizationClientById(clientId);
            setValue(client);
        } catch (err) {
            console.log('err: ', err.toLocaleString());
        }
        setLoading(false);
    };
    return <InputGroup placeholder={loading ? 'Loading...' : 'Derived from CTC Job'} fill value={value?.name} disabled />;
};
