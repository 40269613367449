import React from 'react';
import { Button } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { Link, useHistory } from 'react-router-dom';
import { createFolderTemplate, getFolderTemplates } from 'src/api/folders';
import { Header } from '../../../components/Header';
import { IFolderTemplate } from '../../../api/models/DefaultFolder';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import './index.scss';
import moment from 'moment';

const columns = [
    {
        field: 'name',
        headerName: 'Template Name',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return <Link to={`/settings/documents/templates/${params.row.id}`}>{params.row.name}</Link>;
        },
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return <span>{params.row.created_by.full_name}</span>;
        },
    },
    {
        field: 'updated_at',
        headerName: 'Last Updated',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return <span>{moment(params.row.updated_at).format('MM/DD/YYYY')}</span>;
        },
    },
];
export const Documents: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [templates, setTemplates] = React.useState<IFolderTemplate[]>([]);
    const history = useHistory();
    React.useEffect(() => {
        loadTemplates();
    }, []);

    const loadTemplates = async (load = true) => {
        setLoading(load);
        setError(null);
        try {
            const data = await getFolderTemplates();
            setTemplates(data);
        } catch (err) {
            setError(err.toString());
        }
        setLoading(false);
    };

    const createTemplate = async () => {
        const result = await createFolderTemplate();
        history.push(`/settings/documents/templates/${result.id}`);
    };
    return (
        <>
            <Header
                title="Documents"
                breadcrumbs={['Settings', 'Documents']}
                actionComponent={
                    <>
                        <Button intent="primary" icon="add" onClick={() => createTemplate()}>
                            Create Template
                        </Button>
                    </>
                }
            />
            {error && <p>{error}</p>}
            <Row>
                <Col>
                    <div className="document-table-container">
                        <DataGrid
                            // sx={{
                            //     '& .MuiDataGrid-cell': {
                            //         cursor: 'pointer',
                            //     },
                            // }}
                            disableSelectionOnClick
                            disableColumnSelector
                            // onCellClick={async (params) => {
                            //     if (params.field === 'name') {
                            //         history.push(`/jobs/${params.row.id}/summary`);
                            //     }
                            // }}
                            loading={loading}
                            rows={templates}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};
