import React from 'react';
import { IconName, Icon } from '@blueprintjs/core';
import { SubMenu } from '../SubMenu';
import { ButtonItem, Section } from '@atlaskit/side-navigation';
import { useDialogs } from '../../../hooks/useDialogs';

interface INavigationItem {
    id: string;
    title: string;
    path: string;
    icon: IconName;
    subs?: INavigationItem[];
}

const MENU_ITEMS: INavigationItem[] = [
    {
        id: 'settingsList',
        title: 'Admin Settings',
        path: '/settings',
        icon: 'cog',
        subs: [
            {
                id: 'users',
                title: 'Users',
                path: '/settings/users',
                icon: 'people',
            },
            {
                id: 'roles',
                title: 'Roles',
                path: '/settings/roles',
                icon: 'shield',
            },
            {
                id: 'offices',
                title: 'Offices',
                path: '/settings/offices',
                icon: 'office',
            },
            {
                id: 'customers',
                title: 'Customers',
                path: '/settings/customers',
                icon: 'inherited-group',
            },
            {
                id: 'contacts',
                title: 'Contacts',
                path: '/settings/contacts',
                icon: 'id-number',
            },
            {
                id: 'ctc',
                title: 'CTC',
                path: '/settings/ctc',
                icon: 'dollar',
            },
            {
                id: 'workflows',
                title: 'Workflows',
                path: '/settings/workflows',
                icon: 'data-lineage',
            },
            {
                id: 'milestones',
                title: 'Milestones',
                path: '/settings/milestones',
                icon: 'updated',
            },
            {
                id: 'wbs',
                title: 'WBS',
                path: '/settings/wbs',
                icon: 'diagram-tree',
            },
            {
                id: 'dailys',
                title: 'Dailys',
                path: '/settings/dailys',
                icon: 'array-date',
            },
            {
                id: 'documents',
                title: 'Documents',
                path: '/settings/documents',
                icon: 'folder-open',
            },
        ],
    },
];

const buttonCssFn = (props: any) => {
    return {
        color: props.isSelected ? '#004677' : '#42536e',
        backgroundColor: props.isSelected ? '#ebecf0' : '#fff',
    };
};

export const Settings: React.FC = () => {
    const { setCreateProjectOpen } = useDialogs();
    return (
        <SubMenu
            items={MENU_ITEMS}
            actions={
                <>
                    <Section hasSeparator>
                        <ButtonItem cssFn={buttonCssFn} iconBefore={<Icon icon="add" />} onClick={() => setCreateProjectOpen(true)}>
                            Create Job
                        </ButtonItem>
                    </Section>
                </>
            }
            buttonCssFn={buttonCssFn}
            goBackText="Back to Dashboard"
            goBackPath="/dashboard"
            computePath={(item) => item.path}
        />
    );
};
