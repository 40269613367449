import React from 'react';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';

export interface JobData {
    percentComplete: number;
    contractAmount: number;
    overUnder: number;
    contractEarned: number;
    original: number;
    costToDate: number;
    postedCOTotal: number;
    costAtCompletion: number;
    contractToDate: number;
    adjustedRevenue: number;
    expectedContract: number;
    grossMarginDollar: number;
    grossMarginPercent: number;
    grossBilled: number;
    retentionHeld: number;
    retentionBilled: number;
    netBilled: number;
    jtdCosts: number;
}
interface JobDetailsProps {
    title: string;
    jobData: JobData;
    adjusted?: number;
    ctc?: number;
    isCalc?: boolean;
}
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

export const JobDetails: React.FC<JobDetailsProps> = ({ jobData, title, adjusted = 0, isCalc = false, ctc = 0 }) => {
    const revenueValue = adjusted ? jobData.adjustedRevenue + adjusted : jobData.adjustedRevenue;
    //const updated = (revenueValue / jobData.contractAmount) * 100;

    const expectedContract = jobData.expectedContract + adjusted;
    const grossMargin = jobData.grossMarginDollar + adjusted - ctc;
    const percentage = (grossMargin / expectedContract) * 100;
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                    {title}
                </Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Original Contract:</p>
                    <p>{formatter.format(jobData.original)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Posted CO's:</p>
                    <p>{formatter.format(jobData.postedCOTotal)}</p>
                </Stack>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Contract to Date:</p>
                    <p>{formatter.format(jobData.contractToDate)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Revenue Adj</p>
                    <p className={`${isCalc && 'yellow-box'}`}>{formatter.format(revenueValue)}</p>
                </Stack>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0 text-bold">Expected Contract: </p>
                    <p className="text-bold">{formatter.format(jobData.expectedContract + adjusted)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Cost at Completion:</p>
                    <p>{formatter.format(jobData.costAtCompletion + ctc)}</p>
                </Stack>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Gross Margin: </p>
                    <p>{formatter.format(jobData.grossMarginDollar + adjusted - ctc)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0 text-bold">Gross Margin %: </p>
                    <p className="text-bold">{Math.round(percentage)}%</p>
                </Stack>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Gross Billed: </p>
                    <p>{formatter.format(jobData.grossBilled)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Retention Held: </p>
                    <p>{formatter.format(jobData.retentionHeld)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Retention Billed: </p>
                    <p>{formatter.format(jobData.retentionBilled)}</p>
                </Stack>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0 text-bold">Net Billed: </p>
                    <p className="text-bold">{formatter.format(jobData.netBilled)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Costs to Date: </p>
                    <p>{formatter.format(jobData.costToDate)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Contract Earned: </p>
                    <p>{formatter.format(jobData.contractEarned)}</p>
                </Stack>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Over/Under Billed: </p>
                    <p>{formatter.format(jobData.overUnder)}</p>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <p className="m-0">Percent Complete: </p>
                    <p>{jobData.percentComplete}%</p>
                </Stack>
            </CardContent>
        </Card>
    );
};
